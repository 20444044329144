
.draggedDiv {
    position: fixed;
    z-index: 999;
}

.saveLabel {
    border: 5px solid;
    display: flex;
    background: lightskyblue;
    border-radius: 0px;
    border-color: deepskyblue;
    padding: 0;
    box-sizing: content-box;
    flex-direction: column;
    align-items: flex-end;
    
}
    .saveLabel p {
        float: right;
    }





