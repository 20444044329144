    .categoryHolder, .allergensHolder {
        margin-left:auto;
        margin-top:5px;
        font-size:18px;
        font-weight:normal;

    }
     .allergensUL, .labelsContent .categoryUL {
        width: 100%;
    }


.bulgarian {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.6), rgba(42, 170, 2,0.6), rgba(255,0,0,0.6));
}
.english {
    background-image: linear-gradient(to bottom,rgba(0, 0, 255,0.6),rgba(255, 255, 255, 0.6), rgba(255, 0, 0,0.6),rgba(255, 0, 0,0.6),rgba(255, 255, 255, 0.6),rgba(0, 0, 255,0.6));
}
.german {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(255,0,0,0.6), rgba(255, 255, 0,0.6));
}
.russian {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255,0.5),rgba(0, 0, 255,0.5),rgba(255, 0, 0,0.5));
}

    .button-translate > *:hover {
        background-color: lightskyblue;
    }

 .submitButton {
    font-size: 22px;
    background-color: dodgerblue;
    border-radius: 0;
    text-align: center;
    vertical-align: central;
    padding: 5px;
    left: 0;
    align-self: center;
    margin-bottom: 5px;
    border: none;
}

    .submitButton:hover {
        background-color: greenyellow;
    }

   .submitButton:active {
        background-color: limegreen;
    }