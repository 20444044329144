.error {
    margin:0;
    position:absolute;
    display:flex;
    justify-content:center;
    align-items:center;
    top:90vh;
    left:50vh;
    transform:translateX(-50%);
}
.error > span {
    margin:0;
    padding:0 10px 0 10px;
    display: table-cell;
    height: 8%;
    min-width: 20vw;
    border: 2px solid darkred;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    background-color: red;
    font-size: 1.5em;
    opacity: 1;
    transition: 1s;
}
