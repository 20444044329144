@import url('https://fonts.googleapis.com/css?family=Roboto');

html {
    font-family: 'Roboto', sans-serif;
}


* {
    margin: 0;
    padding: 0;
}
button {
    border-radius:10px;
    border:1px solid black;
    min-height: 20px;
    min-width: 20px;
}

#loadSignsButton {
    margin-left: auto;
    float: right;
}
    #createPDFbutton{
        margin-left:auto;
        float:right;
    }
#SignPreview {
    display: relative;
    margin-left:auto;
    margin-right:auto;
    width: 60%;
}

    #SignPreview canvas {
        margin-top: 1%;
        margin-bottom: auto;
        width: 95%;
    }

.labelsTable > tr > td:first-child {
    text-align:center;
}