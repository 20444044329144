#searchContainer {
    display: flex;
    width: 100%;
    height: 6%;
}
#searchInput {
    width: 100%;
}
#searchButton {
    border-radius: 0;
    min-width: 6%;
    background-color: #1c5b72;
}
    #searchButton svg {
        fill: #fff;
    }

